import { Wistia } from '../../../../../../../wistia_namespace.ts';

class WistiaLogoPlugin extends Wistia.Plugin.Base {
  constructor(video, options) {
    super(video, options);
    this.pluginName = 'wistiaLogo';
    this.video = video;
    this.options = options;
    this._origImpl = video._impl;
    video.setControlEnabled('wistiaLogo', true);
    video.embedded(this.hideIfNoRoom);
    video.bind('widthchange', this.hideIfNoRoom);
  }

  hideIfNoRoom = () => {
    const video = this.video;
    let spaceForPlaybar;

    // there's a chance we dont have a ui yet, so we assume we'll have the spaceForPlaybar method
    if (video._impl.ui && video._impl.ui.spaceForPlaybar) {
      spaceForPlaybar = video._impl.ui.spaceForPlaybar();
    } else {
      spaceForPlaybar = 0;
    }

    if (this.isHidden) {
      spaceForPlaybar -= this.widthWhenHidden;
    }
    if (video._impl.ui && spaceForPlaybar < 0) {
      this.isHidden = true;
      if (video.controls.wistiaLogo) {
        this.widthWhenHidden = video.controls.wistiaLogo.props.width;
      }
      video.setControlEnabled('wistiaLogo', false);
    } else {
      this.isHidden = false;
      video.setControlEnabled('wistiaLogo', true);
    }
  };

  remove() {
    this.video.setControlEnabled('wistiaLogo', false);
    super.remove();
  }
}

Wistia.plugin('wistiaLogo', (video, options) => {
  return new WistiaLogoPlugin(video, options);
});
